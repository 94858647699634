<template>
  
  <div>
    <div class="row mt-10">
      <div class="col-md-12 flex justify-between">
        <vs-button
            class="rounded-full text-white"
            color="primary"
            @click="toggleRoute()"
        >
            <feather-icon icon="UserPlusIcon" class="inline-block align-middle"></feather-icon>
            {{ this.$t('Nuevo Usuario Administrativo') }}
        </vs-button>
        <div class="inline-block row " style="vertical-align: middle; margin-left: 25px !important;">
          <div class="col-1 inline-block">
            <span>{{ this.$t('Buscar') }}: </span>
          </div>
          <div class="col-4 inline-block" style="vertical-align: middle;">
            <div class="border-2 h-12 border-solid rounded-full" style="background-color:white; border-radius: 9px;" @click="$refs['users-search'].focus()">
              <div class="flex h-full">
                  <feather-icon :icon="'SearchIcon'" class="mt-2 mb-2 ml-2" />
                  <input
                      @input="searchAll($event.target.value)"
                      class="h-full w-full border border-none mr-5"
                      ref="users-search"
                      v-model="pagination.name"
                  />
                </div>
            </div>
          </div>
          <div class="col-1 inline-block" style="margin-left: 25px !important;">
            <span>{{ this.$t('Ver') }}: </span>
          </div>
          <div class="col-4 inline-block" style="vertical-align: middle;">
            <div class="flex justify-end w-full">
              <!-- eslint-disable -->
              <vs-select v-model="maxItems" class="w-32 inline-block">
                <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in maxItemsVals" />
              </vs-select>
            </div>
            
          </div>
          <div class="col-2 inline-block" style="margin-left: 25px !important;">
            <vs-button
              style="background-color:#841910 !important"
              class="rounded-full rounded-ful"
              @click="exportData"
            >
                <feather-icon icon="DownloadIcon" class="align-middle"></feather-icon>
            </vs-button>
          </div>
          
        </div>
        
      </div>
    </div>
    <div v-if="!userForm" class="mt-10">
      <!-- <div class="flex justify-between">
        <h1 class="text-info">{{ this.$t('Usuarios Creados') }}</h1>
        <feather-icon
          @click="loadData()"
          icon="RefreshCwIcon"
          :class="{ 'animate-spin': loading }"
          class="cursor-pointer"
        />
      </div> -->

      <vs-table :max-items="maxItems"  pagination :data="all" :noDataText="this.$t( 'Datos no disponibles' ) ">
        <!-- encabezado de tabla -->
        <template slot="thead">
          <vs-th class="text-info bgf">{{ this.$t('Nombre') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Correo Electrónico') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Telefono') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Rol') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Acciones') }}</vs-th>
        </template>
        <!-- cuerpo de tabla -->
        <template slot-scope="{data}">
          <template>
            <div style="min-height:20px"></div>
          </template>
          
          <vs-tr :data="tr" class="tr zoom" v-bind:key="indextr" v-for="(tr, indextr) in data">
            <!-- identification -->
            <!-- <vs-td :data="data[indextr].identification">
              <router-link to="/" class="text-info">{{ data[indextr].identification }}</router-link>
            </vs-td> -->

            <!-- nombre -->
            <vs-td :data="data[indextr].name">
              <span>{{ data[indextr].name }}</span>
            </vs-td>

            <!-- email -->
            <vs-td :data="data[indextr].email">
              <span>{{ data[indextr].email }}</span>
            </vs-td>

            <!-- telefono -->
            <vs-td :data="data[indextr].cell_phone">
              <span>{{ data[indextr].cell_phone }}</span>
            </vs-td>

            <vs-td :data="data[indextr].position">
              <span>{{ data[indextr].position }}</span>
            </vs-td>

            <!-- Editar, eliminar, guardar -->
            <vs-td :data="data[indextr].id">
              <div class="inline-flex">
                  
                <vs-switch
                  class="align-middle"
                  style="margin-top: 13px;"
                  :value="data[indextr].status_id === 1 ? true : false"
                  @click="changeStatus(data[indextr].id)"
                  :disabled="data[indextr].edit"
                />
                <!-- editar -->
                  <vs-button color="transparent" @click="editUser( data[indextr].id )">
                    <feather-icon icon="Edit2Icon" style="color:black !important" class="inline-block align-middle"></feather-icon>
                  </vs-button>
                
                <!-- eliminar -->
                  <vs-button color="transparent" @click="activeDeleteDialog( data[indextr].id )" style="margin-left: -30px;">
                    <feather-icon icon="Trash2Icon" style="color:black !important" class="inline-block align-middle"></feather-icon>
                  </vs-button>
              </div>
            </vs-td>

          </vs-tr>
          <div style="margin-top:20px"> {{ $t('mostrando') }} {{ data.length }} {{ $t('de') }} {{ users.length }}</div>
        </template>
      </vs-table>
    </div>

    <!-- modal eliminar -->
    <vs-prompt :active.sync="activePrompt" buttons-hidden>
      <div class="w-full">
        <vs-button  class="float-right" @click="closeDeleteDialog()" color="transparent" text-color="#666">
          <feather-icon icon='XIcon'  />
        </vs-button>
      </div>
      
      <div class="w-full flex justify-center mb-5">
        <img :src="require(`@/assets/images/elements/Trash.svg`)" alt="Delete Icon" width="128px" />
      </div>
        
      <div class="text-center text-gray-400">
        <h3 class="mb-2">{{ this.$t('Eliminar Usuario') }}</h3>
        <p>{{ this.$t('¿Estás seguro que deseas eliminar definitivamente este usuario creado?') }}</p>

        <p><vs-button style="border: #e32113 solid 2px" class="mt-5 rounded-full"  :disabled="userId <= 0" @click="deleteUser()">{{ this.$t('Eliminar') }}</vs-button>
        <vs-button style="border: #e32113 solid 2px" class="mt-2 ml-5 rounded-full text-primary" @click="closeDeleteDialog()" color="#FFF">{{ this.$t('Cancelar') }}</vs-button></p>
      </div>
    </vs-prompt>

    <EditUserSidebar :sidebar="sidebar" :data="userSelected" @closeSidebar="closeSidebar"/>
  </div>
</template>

<script lang="ts">
import Vue             from 'vue';
import vSelect         from 'vue-select';

import FeatherIcon     from '../../../components/FeatherIcon.vue';
import EditUserSidebar from './EditUserSidebar.vue';
import { Validation }  from '../../../plugins/validation.js';

export default Vue.extend({
  data : () => ({
    all             : [],
    activePrompt    : false,
    userId          : 0,
    userForm        : false,
    defaultCheckbox : true,
    validate        : new Validation(),
    sidebar         : false,
    userSelected    : { id : 0 },
    selected        : [],
    loading         : false,
    maxItems        : 10,
    pagination   : { total: 1, current: 1, page_size: 100, name: '' },
    maxItemsVals    : [5,10,15,20,25,30,35,40,45,50],
    newData: []
  }),

  created() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch( 'stores/getAllStores' );
      await this.$store.dispatch( 'users/getAllRoles' );
      await this.$store.dispatch( 'users/getAllUsers' );
      await this.$store.dispatch( 'location/getAllSigns' );
      this.loading = false;
      this.all = this.users
    },
    async changeStatus( userId ) {
      this.$vs.loading();
      if (this.selected.length > 0){
        
        this.selected.forEach( user => {
          const newUser = {
            id: user.id,
            status_id: user.status_id === 1 ? 2 : 1,
          }
          this.$store.dispatch( 'users/updateUser', newUser );
        });
        await this.$vs.loading.close();
        this.selected = []
      } else {
        const users = this.users.filter( user => user.id === userId );
        const user = users[0];
        const newUser = {
            id: user.id,
            status_id: user.status_id === 1 ? 2 : 1,
        }
        await this.$store.dispatch( 'users/updateUser', newUser );
        this.$vs.loading.close();
      }
      this.$vs.loading.close();
      this.selected = []
      await this.$store.dispatch( 'users/getAllUsers' );
      this.all = this.users
    
    },
    activeDeleteDialog( id ) {
      this.userId = id;
      this.activePrompt = true;
    },
    closeDeleteDialog() {
      this.userId = 0;
      this.activePrompt = false; 
    },
    async deleteUser() {
      this.$vs.loading();
      const res = await this.$store.dispatch( 'users/deleteUser', this.userId );
      this.$vs.loading.close();
      if( res ) this.closeDeleteDialog();
      await this.$store.dispatch( 'users/getAllUsers' );
      this.all = this.users
    },
    getStoreName( id ) {
      const store = this.stores.filter(store => store.id === id);

      if( store.length > 0 ) return store[0].name;
      else return '';

    },
    getUserTypeName( id ) {
      const role = this.userTypes.filter(userType => userType.id === id);
      if( role.length > 0 ) return role[0].name;

      return '';
    },
    editUser( id=0 ) {
      this.userSelected = this.users.find( user => user.id === id );
      this.sidebar      = true;
    },
    async closeSidebar( val ) {
      this.sidebar      = val;
      this.userSelected = { id : 0 };
      await this.$store.dispatch( 'users/getAllUsers' );
      this.all = this.users
    },
    toggleRoute() {
        if( this.route === '/app/users/list' ) this.$router.push({ path : '/app/users/new' });
        else this.$router.push({ path : '/app/users/list' });
    },
    exportData() {
      let data = [];            

      this.$vs.loading();

      if( this.all.length === 0 ) {
        this.$vs.notify({
            title: this.$t('No hay datos en esta consulta'),
            text: '',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
        });
        return;
      }

      data = this.mapData( this.all );

      const headerVal   = [
          "identification",
          "name",
          "email",
          "cell_phone",
          "position",
          "status",
      ];
      const headerTitle = [
          this.$t("Identificacion"),
          this.$t("Nombre completo"),
          this.$t("Email"),
          this.$t("Telefono"),
          this.$t("Rol"),
          this.$t("Estado")
      ];

      const cellAutoWidth = true;
      const self = this;
      
      self.exportData = data;
      
      import('../../../vendor/Export2Excel').then( excel => {
        const list = self.exportData;
        this.$vs.loading.close();
    
        const data = this.formatJson( headerVal, list );

        excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: "usuarios administrativos",
            autoWidth: cellAutoWidth,
            bookType: "xlsx",
          });
            
        });
    },
    mapData( data=[] ) {            
      
      data.forEach( key => {
        let state = this.$t('Activo')
        if( key.status_id != 1 ){ 
          state = this.$t('Inactivo');}
        this.newData.push({
            identification : key.identification,
            name           : `${ key.name } ${ key.lastname }`,
            email          : key.email,
            cell_phone     : key.cell_phone,
            position       : key.position,
            status         : state
        });
      });
      
      return this.newData;
        
    },

    formatJson( filterVal=[], jsonData=[] ) {
        return jsonData.map( v => filterVal.map( j => {
            return v[j];
        }) );
    },
    getTextStatus( statusId ) {
        if( statusId == 1 ){ return this.$t('Activo');}
        else { return this.$t('Inactivo');}
    },
    searchAll(e){
      const l = e.toLowerCase()
      const temp = this.users.filter(function (d) {
        return (d.email.toLowerCase().indexOf(l) !== -1 || d.name.toLowerCase().indexOf(l) !== -1) || !l;
      });
      this.all = temp;
    },
  }, 
  computed : {

    route() {
        return this.$route.path;
    },
    isEdit() {
      const usersEdit = this.users.filter( user => user.edit === true );
      return !!usersEdit.length;
    },
    stores() {                    
      return this.$store.state.stores.stores;
    },
    userTypes() {
      return this.$store.state.users.roles;
    },
    users() {
      const users = this.$store.state.users.users;            

      let usersMapped = users.map(us => {
        let store = us.store.map( store => store.id );
        let ob = Object.assign({}, us);

        ob.edit = false;
        ob.collapsed = true;
        ob.store = store;

        return ob;
      });
      return usersMapped;
    }
  },

  components: {
    'v-select': vSelect,
    FeatherIcon,
    EditUserSidebar,
  },
});
</script>
<style lang="scss">
.rounded-ful{
  border-radius: 15px !important;
  //background-color: white !important;
  border-width: 1px !important;
}

.vs-table-text{
  text-transform: uppercase;
}

/**.tr{
  border-bottom: #B9B9C3 solid 1px;
} */

.bgf{
  background-color: #F3F2F7!important;
  color: #636363 !important;
  text-transform: uppercase !important;
}

th.td-check {
  padding: 0!important;
  background-color: #F3F2F7!important;
}

.zoom:hover {
  //transform: scale(1.02) !important;
  //border:solid
}

.zoom {
  transition: transform .8s;
}

.vs-switch-primary.vs-switch-active {
  background: rgba(var(--vs-primary),1);
}
</style>
