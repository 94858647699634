<template>
    <vs-sidebar click-not-close position-right parent="#app" class="user-sidebar" spacer v-model="localActiveSidebar">
        <div class="mt-6 flex items-center justify-between px-6" slot="header">
            <h4 class="text-white"> {{ this.$t('Editar Usuario') }} </h4>
            <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
        </div>

        <VuePerfectScrollbar class="scroll-area--data-list-add-new p-10">
            <form @submit.prevent="saveUser()" id="userForm">
                <h3 class="text-info">{{ this.$t('Información Básica') }}</h3>
                <div class="vx-row mt-5">
                  <!-- nombres -->
                  <div class="vx-col w-full">
                      <label for="name"><b>{{ this.$t('Nombres') }}</b></label>
                      <vx-input-group>
                          <vs-input
                              id="name"
                              name="name"
                              v-model="user.name"
                              :danger="!!validate.nameError"
                              val-icon-danger="clear"
                              autofocus
                              required
                          />
                      </vx-input-group>
                      <p class="text-danger text-sm" v-if="!!validate.nameError">{{ validate.nameError }}</p>
                  </div>
                </div>

                <div class="vx-row mt-5">
                  <!-- celular -->
                    <div class="vx-col w-full">
                        <label for="cellphone"><b>{{ this.$t('Celular') }}</b></label>
                        <vx-input-group>
                            <vs-input
                                id="cellphone"
                                name="cellphone"
                                val-icon-danger="clear"
                                v-model="user.cell_phone"
                                class=""
                            />
                        </vx-input-group>
                    </div>
                </div>
                <div class="vx-row mt-5">
                    <!-- Tipo de Usuario -->
                    <div class="vx-col w-full">
                        <label for="cellphone"><b>{{ this.$t('Rol') }}</b></label>
                        <vs-select class="w-full" label="" v-model="user.role_id">
                            <vs-select-item v-bind:key="item.id" :value="item.id" :text="item.name" v-for="item in roles" />
                        </vs-select>
                    </div>
                </div>
                <div class="vx-row mt-5">
                  <!-- correo electronico -->
                  <div class="vx-col w-full mt-2">
                      <label for="email"><b>{{ this.$t('Correo Electrónico') }}</b></label>
                      <vx-input-group>
                          <vs-input
                              id="email"
                              name="email"
                              type="email"
                              :danger="!!validate.emailError"
                              val-icon-danger="clear"
                              v-model="user.email"
                              placeholder="Ej. carlos.cardona@example.com"
                          />
                      </vx-input-group>
                      <p class="text-danger text-sm" v-if="!!validate.emailError">{{ validate.emailError }}</p>
                  </div>
                </div>
                    
                <div class="vx-col w-full mt-5">
                  <!-- TODO validar tipo de nit y numero de nit -->
                  <label for="nit"><b>{{ this.$t('Contraseña') }}</b></label>
                  <div class="">
                      <vx-input-group>
                          <vs-input
                              id="password"
                              name="password"
                              class="w-full"
                              placeholder="******"
                              v-model="user.password"
                              :disabled="edit"
                              :type=" isVisiblePassword ? 'text' : 'password' "
                          />
                          <template slot="append" v-if="!edit">
                              <div class="append-text btn-addon">
                              <vs-button
                                  style="background-color:#b0b0a673 !important;"
                                  color="dark"
                                  type="flat"
                                  :disabled="edit"
                                  icon-pack="feather"
                                  :icon=" isVisiblePassword ? 'icon-eye' : 'icon-eye-off'"
                                  @click="isVisiblePassword = !isVisiblePassword"
                              ></vs-button>
                              </div>
                          </template>
                      </vx-input-group>
                  </div>
                  <p class="text-danger text-sm" v-if="!!validate.passwordError">{{ validate.passwordError }}</p>
                </div>
              
            </form>
        </VuePerfectScrollbar>
        
        <div class=" items-center p-6" slot="footer">
            <button type="button" class="vs-component vs-button vs-button-danger vs-button-border" @click="localActiveSidebar = false">
                {{ this.$t('Cancelar') }}
            </button>

            <button type="submit" form="userForm" class="vs-component vs-button vs-button-info vs-button-filled float-right">
                {{ this.$t('Guardar') }}
            </button>
        </div>
    </vs-sidebar>
</template>

<script lang="ts">
import Vue                 from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect             from 'vue-select';

import { Validation }      from '../../../plugins/validation.js';
import AuthService         from '../../../auth/authService.js';

export default Vue.extend({
    props: {
        sidebar : {
            required : true,
            type     : Boolean,
        },
        data    : { required : true },
    },

    data() {
        return {
            validate     : new Validation(),
            validFormats : ['jpg', 'jpeg', 'png'],
            image        : {
                progress : 0,
                error    : '',
                file     : null,
            },
            edit        : false,
            select_all_stores : false,
            isVisiblePassword  : false
        }
    },

    created() {
        this.$vs.loading();

        const getApps = this.superAdminAccess ? this.$store.dispatch( 'applications/getAllApplications' ) : new Promise((resolve) => resolve(true) );
        const proms = [
            this.$store.dispatch( 'users/getAllRoles' ),
            this.$store.dispatch( 'users/getAllStores' ),
            getApps,
        ];
        Promise.all( proms ).then( () => {
            this.$vs.loading.close();
        });
    },

    watch: {
        'data.id'( val ) {
            if( val == 0 ) return;
            this.$vs.loading();
            this.$store.dispatch( 'users/getUserById', val )
                .then( () => {
                    this.$vs.loading.close();
                    this.mapUserStores();
                });
        },
        'user.name'( val ) {
            this.validate.validateName( val );
        },
        'localActiveSidebar'(){
          this.clearInputs()
        }
    },

    methods: {
        clearInputs(){
          this.user.name = ''
          this.user.cell_phone = ''
          this.user.email = ''
          this.user.password = ''
          this.user.role_id = 0
        },
        saveUser() {
            const isValidName           = this.validate.validateName( this.user.name );
            const isValidPhone          = this.validate.validatePhone( this.user.cell_phone );
            const isValidEmail          = this.validate.validateEmail( this.user.email );
            if (this.user.password){
                const isValidPwd            = this.validate.validatePassword( this.user.password );
                if( !isValidPwd ) 
                    { this.$vs.notify({
                        title: 'Alerta',
                        text: this.$t('Valide los campos antes de continuar'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'warning'
                    });
                    return;
                }
            }
            if(
                !isValidName ||
                !isValidPhone ||
                !isValidEmail
              ) { this.$vs.notify({
                    title: 'Alerta',
                    text: this.$t('Valide los campos antes de continuar'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
                return;
            }

            this.$vs.loading();
            const objposition = this.roles.find(item => item.id === this.user.role_id);
            
            const payload = {
                name:this.user.name,
                cell_phone:this.user.cell_phone,
                email:this.user.email,
                role_id:this.user.role_id,
                position   : objposition.name,
                id         : this.user.id
            }
            if (this.user.password){
                payload['password'] = this.user.password
            }
            this.$store.dispatch( 'users/updateUser', payload )
                .then(res => {
                    this.$vs.loading.close();
                    this.isVisiblePassword = false
                    this.user = {}
                    if (res ) this.localActiveSidebar = false;
                });
            
        },
        mapUserStores() {
            const stores = this.user.store;            
            const storesMapped = stores.map( store => store.id );
            
            this.user.store = storesMapped;
        },
        toggleAllstores(val) {
            if( val ) {
                this.user.stores = [];
                this.stores.forEach(store => {
                    setTimeout(() => {
                        this.user.stores.push(store.id);
                    }, 1);
                });
            } else {
                this.user.stores = [];
            }
        },
        isStoreSelected(store_id=0) {
            const store = this.user.store.find(s_id => s_id === store_id);
            return !!store;
        },
    },

    computed: {
        localActiveSidebar: {
            get: function() {
                return this.sidebar;
            },
            set: function( val ) {                
                this.$emit( 'closeSidebar', val );
                this.$store.dispatch( 'users/getAllUsers' );
            }
        },

        user: {
            cache: true,
            get() {
                const self = this;
                const user = this.$store.state.users.user;

                let userProxy = new Proxy( user, {
                    set (obj, key, value) {
                        self.$store.commit('users/SET_USER', {
                            ...obj,
                            [key]: value,
                        });    
                        return true
                    }
                });

                return userProxy;
            },
            set( val ) {
                this.$store.commit('users/SET_USER', val );
            }
            
        },
        documentTypes() {
            return this.$store.state.users.identificationTypes;
        },
        roles() {
            return this.$store.state.users.roles;
        },
        stores() {
            return this.$store.state.users.stores;
        },
        callsigns() {
            const callsigns = this.$store.state.location.callsigns;
            const signs = callsigns.map(sign => {
                let ob = Object.assign({}, sign);
                ob.sign = `+${sign.callingCodes[0]}`;
                return ob;
            });

            return signs;
        },
        superAdminAccess() {
            return AuthService.isValidModule( 'OWNER' );
        },
        apps() {
            return this.$store.state.applications.apps;
        },
        userStatus() {
            return this.$store.state.users.userStatus;
        },

    },

    components: {
        VuePerfectScrollbar,
        vSelect,
    }
});
</script>

<style lang="scss" scoped>
.user-sidebar {
    ::v-deep .vs-sidebar--background {
        z-index: 52010;
    }
    
    ::v-deep .vs-sidebar {
        position: fixed;
        z-index: 52010;
        width: 400px;
        max-width: 90vw;
        .vs-sidebar--header {
            background: #841910;
            color: #FFF;
        }
    }
}

.scroll-area--data-list-add-new {
    height: 79vh;
}

</style>
